import React from "react"
import styled from "styled-components"
import { Heading } from "@atoms"
import { Link } from "gatsby"
import {
  HeroContainer,
  BreadCrumbFlex,
  TextContentContainer,
  CategoriesFlex,
  CategoryTag,
  Blur,
  IconImg
} from "./styles/HeroNewsroomDetail.styled"
import OptimizedImage from "../../utils/optimizedImage"

export const HeroNewsroomDetail = ({ postData }) => {
  return (
    <HeroContainer
      data-comp={HeroNewsroomDetail.displayName}
    >
      <OptimizedImage image={postData.featuredImage?.gatsbyImageData} src={postData.featuredImage?.file.url} alt="" className="background-image" loading="eager"/>
      <BreadCrumbFlex>
          <Link href="/">
            <span style={{fontWeight: 400, textDecoration: 'none', color: 'white', transition: 'color 200ms ease' }}>Home</span>
          </Link>
          <IconImg>
            <img src="/icons/chevron-right-white.svg" alt="Breadcrumb Chevron" />
            <img src="/icons/chevron-right-black.svg" alt="Breadcrumb Chevron" />
          </IconImg>
        <Link href="/newsroom">
          <span style={{ fontWeight: 400, textDecoration: 'none', color: 'white', transition: 'color 200ms ease' }}>Newsroom</span>
        </Link>
        <IconImg>
          <img src="/icons/chevron-right-white.svg" alt="Breadcrumb Chevron" />
          <img src="/icons/chevron-right-black.svg" alt="Breadcrumb Chevron" />
        </IconImg>
          <span>{postData?.externalTitle}</span>
      </BreadCrumbFlex>
      <TextContentContainer>
        <CategoriesFlex> 
            <span>Newsroom</span>
        </CategoriesFlex>
        <Heading
          background="dark"
          headingSize="H2"
        >
          {postData.category &&
            postData.category.map(category => {
              return (
                <CategoryTag
                  style={{ backgroundColor: category?.backgroundColor, color: category?.fontColor || '#1B1B1B' }} title="Newsroom Hero Background Image"
                >
                  {category?.title}
                </CategoryTag>
              )
            })}
        </Heading>
      </TextContentContainer>
      <Blur/>
    </HeroContainer>
  )
}

HeroNewsroomDetail.displayName = "HeroNewsroomDetail"
